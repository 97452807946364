<div *ngFor="let toast of toasts; let i = index" class="toast-container position-fixed top-0 end-0 p-2" [ngStyle]="{ 'margin-top': i * 100 + 'px' }" style="z-index: 1050;">
    <div [ngClass]="{
      'toast': true,
      'show': true,
      'toast-success': toast.type === 'success',
      'toast-error': toast.type === 'error',
      'toast-info': toast.type === 'info',
      'toast-warning': toast.type === 'warning'
    }" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-body d-flex justify-content-between align-items-center">
        <span>{{ toast.message }}</span>
        <button mat-icon-button aria-label="Close" class="btn btn-sm text-light" (click)="removeToast(i)">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    </div>
  </div>
  