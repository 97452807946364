import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { NotFoundComponent } from './not-found/not-found.component';
import { UnAuthorizedComponent } from './un-authorized/un-authorized.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { PageLoaderComponent } from './page-loader/page-loader.component';
import { ToastComponent } from './toast/toast.component';
import { MatIconModule } from '@angular/material/icon';
import { AuthInterceptorService } from './interceptors/auth.service';
import { ResetPasswordRedirectComponent } from './reset-password-redirect/reset-password-redirect.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    UnAuthorizedComponent,
    PageLoaderComponent,
    ToastComponent,
    ResetPasswordRedirectComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatIconModule,
    BrowserAnimationsModule
  ],
  providers: [
    provideAnimationsAsync(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
