import { Component } from '@angular/core';

@Component({
  selector: 'app-un-authorized',
  templateUrl: './un-authorized.component.html',
  styleUrl: './un-authorized.component.scss'
})
export class UnAuthorizedComponent {

}
