import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnAuthorizedComponent } from './un-authorized/un-authorized.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthGuard } from './guard/auth.guard';
import { AuthRedirectGuard } from './guard/auth-redirection.guard';
import { ResetPasswordRedirectComponent } from './reset-password-redirect/reset-password-redirect.component'; // Import the component

const routes: Routes = [
  // Redirect the root path to the auth module
  { path: '', redirectTo: '/auth', pathMatch: 'full' },

  // Lazy load the auth module
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule), canActivate: [AuthRedirectGuard] },

  // Reset password redirection using a component
  {
    path: 'reset_password',
    component: ResetPasswordRedirectComponent,
  },

  // Define the unauthorized route
  { path: 'unauthorized', component: UnAuthorizedComponent },

  // Define the not-found route
  { path: 'not-found', component: NotFoundComponent },

  // Dashboard Routing module 
  { path: 'admin-dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard] },
  { path: 'back-office-dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard] },

  // Catch-all route for handling 404 errors
  { path: '**', redirectTo: '/not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
