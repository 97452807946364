import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Retrieve the token from localStorage or any storage mechanism you use
    const authToken = localStorage.getItem('accessToken');

    // If the token is available, add the Authorization header
    let clonedReq = req;
    if (authToken) {
      clonedReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${authToken}`)
      });
    }

    // Handle the request and catch errors
    return next.handle(clonedReq).pipe(
      catchError((error: HttpErrorResponse) => {
        // Check if the error status is 401 (Unauthorized)
        if (error.status === 401) {
          // Clear the stored tokens if necessary
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');

          // Navigate to the login page
          this.router.navigate(['/auth/login']);
        }

        // Check if the error status is 403 (Forbidden)
        if (error.status === 403) {
          // Navigate to the unauthorized page
          this.router.navigate(['/unauthorized']);
        }
        // Pass the error to the caller
        return throwError(error);
      })
    );
  }
}
