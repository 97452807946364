import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../auth/service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthRedirectGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): boolean {
    if (this.authService.isAuthenticated()) {
      // console.log('authentic');
      
      this.router.navigate(['/admin-dashboard']);
      return false; // Prevent navigation to the root path (/auth/login)
    } else {
      // console.log('authentic not');
      return true; // Allow navigation to the root path (Login page)
    }
  }
}
