import { Component } from '@angular/core';
import { PageLoaderService } from '../service/page-loader.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrl: './page-loader.component.scss'
})
export class PageLoaderComponent {
  isLoading = false;

  constructor(private loaderService: PageLoaderService) { }

  ngOnInit(): void {
    this.loaderService.loading$.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }
}
