import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-reset-password-redirect',
  templateUrl: './reset-password-redirect.component.html',
  styleUrls: ['./reset-password-redirect.component.scss']
})
export class ResetPasswordRedirectComponent implements OnInit {

  constructor(
    private route: ActivatedRoute, 
    private router: Router, 
    private ngZone: NgZone // Inject NgZone for safe navigation
  ) { }

  ngOnInit(): void {
    // Retrieve the 't' query parameter
    let resetPasswordToken = this.route.snapshot.queryParamMap.get('t');
  
    if (resetPasswordToken) {
      // Encode the token to ensure safe URL usage
      resetPasswordToken = encodeURIComponent(resetPasswordToken);
  
      console.log(`Redirecting to: auth/external/update-password/${resetPasswordToken}`);
  
      // Safe navigation using NgZone
      this.ngZone.run(() => {
        this.router.navigateByUrl(`auth/external/update-password/${resetPasswordToken}`);
      });
    } else {
      console.error('Reset password token not found in query parameters.');
      this.router.navigate(['/not-found']);
    }
  }
  
}
