import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs'; 
import { ToastService } from '../service/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit, OnDestroy {
  toasts: { message: string; type: 'success' | 'error' | 'info' | 'warning' }[] = [];
  private toastSubscription: Subscription | null = null;

  constructor(private toastService: ToastService) {}

  ngOnInit(): void {
    this.toastSubscription = this.toastService.toast$.subscribe(toast => {
      if (toast) {
        this.toasts.push(toast);
        // Hide toast after default time (or custom time) based on toast type
        const duration = this.getDurationByType(toast.type);
        setTimeout(() => this.removeToast(this.toasts.indexOf(toast)), duration);
      }
    });
  }

  getDurationByType(type: 'success' | 'error' | 'info' | 'warning'): number {
    const durationMap: { [key: string]: number } = {
      success: 3000,
      error: 6000,
      info: 10000,
      warning: 6000
    };
    return durationMap[type] || 3000;
  }

  removeToast(index: number): void {
    this.toasts.splice(index, 1);
  }

  ngOnDestroy(): void {
    this.toastSubscription?.unsubscribe();
  }
}
